<template>
  <TransitionRoot as="template" :show="open">
    <Dialog as="div" class="relative z-50" @close="close">
      <TransitionChild
        as="template"
        enter="ease-out duration-300"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="ease-in duration-200"
        leave-from="opacity-100"
        leave-to="opacity-0"
      >
        <div
          class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
        />
      </TransitionChild>

      <div class="fixed inset-0 z-50 w-screen overflow-y-auto">
        <div
          class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0"
        >
          <TransitionChild
            as="template"
            enter="ease-out duration-300"
            enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enter-to="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leave-from="opacity-100 translate-y-0 sm:scale-100"
            leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <DialogPanel
              class="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-md sm:p-6"
            >
              <div class="flex items-center">
                <ExclamationTriangleIcon
                  v-if="dialog.type !== 'none'"
                  :class="['h-12 w-12 mr-4', typeColor]"
                  aria-hidden="true"
                />
                <div class="mt-3 text-center sm:mt-0 sm:text-left">
                  <DialogTitle
                    as="h3"
                    class="text-base font-semibold leading-6 text-gray-900"
                  >
                    {{ dialog.title }}
                  </DialogTitle>
                </div>
              </div>
              <div class="mt-2">
                <p class="text-sm text-gray-500">
                  {{ dialog.message }}
                </p>
              </div>
              <div class="mt-2" v-if="dialog.inputType">
                <InputLabel v-if="dialog.inputLabel" class="mb-1">{{
                  dialog.inputLabel
                }}</InputLabel>
                <TextInput v-if="dialog.inputType === 'text'" v-model="text" />
              </div>

              <div class="mt-5 flex space-x-4">
                <SecondaryButton
                  class="w-full"
                  @secondary-button-clicked="close"
                  >{{ dialog.closeButton }}</SecondaryButton
                >
                <PrimaryButton
                  class="w-full"
                  @primary-button-clicked="confirm"
                  >{{ dialog.confirmButton }}</PrimaryButton
                >
              </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script setup lang="ts">
import {
  Dialog,
  DialogPanel,
  DialogTitle,
  TransitionRoot,
  TransitionChild,
} from '@headlessui/vue';
import { ExclamationTriangleIcon } from '@heroicons/vue/20/solid';
import { DialogInterface } from './dialog';
import SecondaryButton from '../../atoms/Buttons/SecondaryButton.vue';
import PrimaryButton from '../../atoms/Buttons/PrimaryButton.vue';
import { computed, ref } from 'vue';
import InputLabel from '../../atoms/Input/InputLabel.vue';
import TextInput from '../../atoms/Input/TextInput.vue';

const props = defineProps<{
  open: boolean;
  dialog: DialogInterface;
}>();

const text = ref();

const emits = defineEmits<{
  (event: 'update:open', value: boolean): true;
}>();

const close = () => {
  if (props.dialog.onClose) {
    props.dialog.onClose({ input: text.value });
    text.value = null;
  }
  emits('update:open', false);
};

const typeColor = computed(() => {
  const typeMap = {
    error: 'text-red-600',
    warning: 'text-yellow-600',
    info: 'text-indigo-600',
    none: 'text-indigo-600',
  };
  return typeMap[props.dialog.type];
});

const confirm = () => {
  if (props.dialog.onConfirm) {
    props.dialog.onConfirm({ input: text.value });
    text.value = null;
  }
  close();
};
</script>
